import { Link } from "gatsby";
import * as React from "react";

type TagListItemProps = Queries.TagListItemPropsFragment;

export const TagListItem: React.FC<TagListItemProps> = (props) => {
  return (
    <li className="pt-6 pb-4">
      <Link to={"/tag/" + props.contentful_id}>
        <span className="border-2 border-gray-500 py-2 px-3 text-gray-500 transition-all hover:border-gray-700 hover:text-gray-700 ">
          {props.name}
        </span>
      </Link>
    </li>
  );
};
